/* eslint-disable default-case */
/* global Liferay */

import { getNextLesson, getPreviousLesson } from "../../utils/util";
import { forwardRef, useCallback, useEffect, useState } from "react";
import {
  getCurrentLanguageKey,
  getDPTAssetId,
  isSignedIn,
} from "../../utils/util";
import { useTranslation } from "react-i18next";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {
  loadExerciseQuestions,
  postExerciseResult,
} from "../../services/exercise";
import ClayAlert from "@clayui/alert";
import { enrollToCourse, getCourseByAssetTypeAndId } from "../../services/course";
import { getUserEnrollmentByCourseId } from "../../services/enrollment";
import CourseBottomNavigation from "./QuizBottomNavigation";
import QuizHome from "./QuizHome";
import QuizProgressBar from "./QuizProgressBar";
import QuizResultSheet from "./QuizResultSheet";
import QuizQuestions from "./QuizQuestions";


const parseAnswers = (answers) => {
  return JSON.parse(answers);
};

const ExerciseView = forwardRef((props, ref) => {
  const {
    exerciseId,
    height,
    educationPageUrl,
    badgePageUrl,
    enableBackButton,
  } = props;

  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();

  const [active, setActive] = useState(-1);
  const [answers, setAnswers] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [firstQuestion, setFirstQuestion] = useState(false);
  const [isKnoledgeCheck, setIsKnoledgeCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleInfo, setModuleInfo] = useState({});
  const [nextLesson, setNextLesson] = useState(null);
  const [prevLesson, setPrevLesson] = useState(null);
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState(null);
  const [scoreSheetOverall, setScoreSheetOverall] = useState(null);
  const [showError, setShowError] = useState(false);
  const { linkPrefix } = props;
  const [courseId, setCourseId] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userEnrollmentId, setUserEnrollmentId] = useState(null);
  const [completedAssetIds, setCompletedAssetsIds] = useState("");

  const currentQuizzId = Liferay.ThemeDisplay.getLayoutURL().split("/").pop();

  useEffect(() => {
    const fetchCourseId = async () => {
      const response = await getCourseByAssetTypeAndId("quiz", currentQuizzId);
      setCourseId(response);
    };

    fetchCourseId();
  }, [currentQuizzId]);

  useEffect(() => {
    if (courseId) {
      const fetchUserEnrollment = async () => {
        const result = await getUserEnrollmentByCourseId(courseId);

        setUserEnrollmentId(
          result?.items?.length > 0 ? result.items[0].id : ""
        );
        setCompletedAssetsIds(
          result?.items?.length > 0 ? result.items[0].completedAssetIds : []
        );
      };

      fetchUserEnrollment();
    }
  }, [courseId]);

  useEffect(() => {
    if (userEnrollmentId) {
      setIsEnrolled(true);
    }
  }, [userEnrollmentId, courseId]);

  const handleRegisterUser = () => {
    if (!isEnrolled) {
      enrollToCourse(courseId, currentQuizzId);
    }

    setFirstQuestion(true);
  };

  const prepareAnswersObject = (questions) => {
    let answersObject = {};

    questions.forEach((question) => {
      switch (question.type) {
        case "selectSingleChoice": {
          answersObject[question.id] = {};

          answersObject[question.id] = null;

          break;
        }
        case "selectMultipleChoice": {
          answersObject[question.id] = {};

          question.answers.forEach((answer) => {
            answersObject[question.id][answer.id] = false;
            <QuizProgressBar
              active={active}
              questions={questions}
              progress={progress}
            />;
          });

          break;
        }
        case "freeText": {
          answersObject[question.id] = "";

          break;
        }
      }
    });

    setAnswers(JSON.stringify(answersObject));
  };

  const handleClearAnswers = useCallback(() => {
    prepareAnswersObject(questions);
  }, [questions]);

  const validateCurrentQuestion = useCallback(
    (questions) => {
      let currentQuestion = questions[active];

      let isValid = false;

      let currentAnswers = parseAnswers(answers);

      switch (currentQuestion.type) {
        case "selectSingleChoice": {
          isValid =
            currentAnswers[currentQuestion.id] &&
            currentAnswers[currentQuestion.id] !== "";
          break;
        }

        case "selectMultipleChoice": {
          if (!currentAnswers[currentQuestion.id]) {
            isValid = false;
          } else {
            const answerKeys = Object.keys(currentAnswers[currentQuestion.id]);

            isValid = answerKeys.some(
              (key) => currentAnswers[currentQuestion.id][key]
            );
          }

          break;
        }

        case "freeText": {
          isValid =
            currentAnswers[currentQuestion.id] &&
            currentAnswers[currentQuestion.id].length > 0
              ? true
              : false;

          break;
        }
      }

      setShowError(!isValid);

      return isValid;
    },
    [active, answers]
  );

  const handleSubmit = useCallback(async () => {
    if (!validateCurrentQuestion(questions)) {
      return;
    }

    setActive((prevActive) => {
      if (prevActive + 1 < questions.length) {
        return prevActive + 1;
      }
      return prevActive;
    });

    setIsLoading(true);

    setScoreSheetOverall(
      await postExerciseResult(assetId, parseAnswers(answers))
    );

    setIsLoading(false);
  }, [questions, validateCurrentQuestion]);

  const handleNextQuestion = useCallback(() => {
    if (!validateCurrentQuestion(questions)) {
      return;
    }

    setActive((prevActive) => {
      if (prevActive + 1 < questions.length) {
        return prevActive + 1;
      }
      return prevActive;
    });
  }, [questions, validateCurrentQuestion]);

  const handlePreviousQuestion = useCallback(() => {
    if (active - 1 >= 0) {
      setActive(active - 1);
    }
  }, [active, questions]);

  const handleQuizReset = () => {
    handleClearAnswers();
    setActive(0);
    setProgress(0);
    setScoreSheetOverall(null);
  };

  useEffect(() => {
    if (getDPTAssetId() && getDPTAssetId().toString().length > 0)
      loadExerciseQuestions(getDPTAssetId()).then((result) => {
        setQuestions(result);

        prepareAnswersObject(result);

        setActive(0);
      });
  }, []);

  useEffect(() => {
    if (questions && questions.length > 0) {
      let currentProgress = 0;

      currentProgress = ((active + 1) / questions.length) * 100;

      setProgress(currentProgress);
    }
  }, [active, questions]);

  useEffect(() => {
    changeLanguage(getCurrentLanguageKey()).then(() => {});

    if (exerciseId && exerciseId.toString().length > 0) {
      setAssetId(exerciseId);
    } else {
      setAssetId(getDPTAssetId());
    }
  }, [changeLanguage]);

  useEffect(() => {
    const fetchData = async () => {
      Liferay.Util.fetch(`/o/c/quizes/${getDPTAssetId()}`)
        .then((response) => response.json())
        .then((response) => {
          setIsKnoledgeCheck(response.isKnowledgeCheck);
          setModuleInfo({ passingScore: response.passingScore });
        });
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (scoreSheetOverall) {
      const titleDuration = document.querySelector(".title-duration");
      if (titleDuration) {
        titleDuration.style.display = "none";
      }
    }

    return () => {
      const titleDuration = document.querySelector(".title-duration");
      if (titleDuration) {
        titleDuration.style.display = "";
      }
    };
  }, [scoreSheetOverall]);

  useEffect(() => {
    Liferay.once("lessonNavigationUpdated", (event) => {
      setNextLesson(getNextLesson());
      setPrevLesson(getPreviousLesson());
    });
  }, []);

  return (
    <div>
      {isLoading && <ClayLoadingIndicator displayType="secondary" size="sm" />}

      {scoreSheetOverall && (
        <>
          <QuizResultSheet
            isEnrolled={isEnrolled}
            currentQuizzId={currentQuizzId}
            userEnrollmentId={userEnrollmentId}
            completedAssetIds={completedAssetIds}
            handleReset={handleQuizReset}
            badgePageUrl={badgePageUrl}
            educationPageUrl={educationPageUrl}
            scoreSheetOverall={scoreSheetOverall}
            isKnowledgeCheck={isKnoledgeCheck}
            questions={questions}
          />

          {isKnoledgeCheck && (
            <CourseBottomNavigation
              isEnrolled={isEnrolled}
              courseId={courseId}
              currentQuizzId={currentQuizzId}
              completedAssetIds={completedAssetIds}
              linkPrefix={linkPrefix}
              userEnrollmentId={userEnrollmentId}
              nextLesson={nextLesson}
              prevLesson={prevLesson}
            />
          )}
        </>
      )}

      {!scoreSheetOverall && !firstQuestion && questions && (
        <>
          <QuizProgressBar
            active={-1}
            questions={questions}
            progress={0}
          />

          <QuizHome
            isKnoledgeCheck={isKnoledgeCheck}
            moduleInfo={moduleInfo}
            questions={questions}
            setFirstQuestion={setFirstQuestion}
            handleRegisterUser={handleRegisterUser}
            isEnrolled={isEnrolled}
          />
        </>
      )}

      {!scoreSheetOverall && firstQuestion && questions && (
        <>
          <QuizProgressBar
            active={active}
            questions={questions}
            progress={progress}
          />

          {showError && (
            <div className={"my-4"}>
              <ClayAlert displayType="warning" title="Warning" role={null}>
                {t("missing-answer")}
              </ClayAlert>
            </div>
          )}

          <QuizQuestions
            active={active}
            questions={questions}
            answers={answers}
            parseAnswers={parseAnswers}
            setAnswers={setAnswers}
            height={height}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            enableBackButton={enableBackButton}
            handlePreviousQuestion={handlePreviousQuestion}
            handleNextQuestion={handleNextQuestion}
          />

          {isKnoledgeCheck && isSignedIn() && (
            <CourseBottomNavigation
              isEnrolled={isEnrolled}
              courseId={courseId}
              currentQuizzId={currentQuizzId}
              completedAssetIds={completedAssetIds}
              linkPrefix={linkPrefix}
              userEnrollmentId={userEnrollmentId}
              nextLesson={nextLesson}
              prevLesson={prevLesson}
            />
          )}
        </>
      )}
    </div>
  );
});

export default ExerciseView;
