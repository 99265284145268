import { request } from "../utils/request";
import { config } from "../utils/constants";
import { getCurrentSiteId } from "../utils/util";

export const getBadgeById = (badgeId) => {
  return request({
    url: `${config.badgeEndpoint}${badgeId}`,
    method: "get",
  });
};

export const getUserBadgesByUserId = (userId) => {
  return request({
    url: `${
      config.userBadgeEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_userBadges_userId eq '${userId}'`,
    method: "get",
  });
};
