import React, { useEffect, useState } from "react";
import { getCurrentUserId, splitStringtoArray } from "../../utils/util";
import { getBadgeById, getUserBadgesByUserId } from "../../services/badge";
import { getActiveCoursesByUserEnrollments } from "../../services/enrollment";
import {
  getCourseByAssetTypeAndId,
  getFirstLessonIdByCourseId,
} from "../../services/course";
import "../../index.css";
import UserDashboardCourseCard from "./UserDashboardCourseCard";
import UserDashboardSectionBanner from "./UserDashboardSectionBanner";
import UserDashboardEmptySection from "./UserDashboardEmptySection";
import UserDashboardBadgeCard from "./UserDashboardBadgeCard";

const UserDashboardView = () => {
  const [userCompleteEnrollments, setUserCompleteEnrollments] = useState([]);
  const [userInProgressEnrollments, setUserInProgressEnrollments] = useState(
    []
  );
  const [userBadges, setUsersBadges] = useState([]);

  useEffect(() => {
    const fetchUserBadges = async () => {
      const userBadges = await getUserBadgesByUserId(getCurrentUserId());

      const userBadgesList = await Promise.all(
        userBadges.items.map(async (item) => {
          const badgeCourse = await getCourseByAssetTypeAndId(
            "quiz",
            item.quizId
          );
          const userBadge = await getBadgeById(item.badgeId);

          return {
            course: badgeCourse,
            image: userBadge.badge.link.href,
            title: userBadge.title,
            year: userBadge.year,
          };
        })
      );
      setUsersBadges(userBadgesList);
    };

    const fetchUserEnrollments = async () => {
      const activeCourses = await getActiveCoursesByUserEnrollments();
      const passedQuizCourses = userBadges
        .filter((item) => item.course)
        .map((item) => item.course);

      const userEnrolledCourseList = await Promise.all(
        activeCourses.items.map(async (item) => {
          const firstLesson = await getFirstLessonIdByCourseId(
            item.r_courseEnrollment_c_courseId
          );

          const completedAssets = splitStringtoArray(item.completedAssetIds);
          const isCompletedCourseByAssets =
            completedAssets.length >=
            item.r_courseEnrollment_c_course.totalAssets;
          const isCompletedCourseByQuiz = passedQuizCourses.includes(
            item.r_courseEnrollment_c_courseId
          );

          const isCourseComplete =
            isCompletedCourseByAssets || isCompletedCourseByQuiz;

          const courseProgress = parseInt(
            (
              (completedAssets.length /
                item.r_courseEnrollment_c_course.totalAssets) *
              100
            ).toFixed(0)
          );

          return {
            courseComplete: isCourseComplete,
            courseProgress: courseProgress,
            description: item.r_courseEnrollment_c_course.description,
            firstLesson: firstLesson,
            id: item.r_courseEnrollment_c_courseId,
            title: item.r_courseEnrollment_c_course.title,
          };
        })
      );

      setUserCompleteEnrollments(
        userEnrolledCourseList.filter((item) => item.courseComplete)
      );
      setUserInProgressEnrollments(
        userEnrolledCourseList.filter((item) => !item.courseComplete)
      );
    };

    fetchUserBadges();
    fetchUserEnrollments();
  }, []);

  return (
    <div className="user-dashboard">
      <UserDashboardSectionBanner section="inprogress" />
      {userInProgressEnrollments ? (
        <UserDashboardCourseCard userEnrollments={userInProgressEnrollments} />
      ) : (
        <UserDashboardEmptySection section="inprogress-no-courses" />
      )}

      <UserDashboardSectionBanner section="completed" />
      {userCompleteEnrollments ? (
        <UserDashboardCourseCard userEnrollments={userCompleteEnrollments} />
      ) : (
        <UserDashboardEmptySection section="completed-no-courses" />
      )}

      <UserDashboardSectionBanner section="badges" />
      {userBadges ? (
        <UserDashboardBadgeCard userBadges={userBadges} />
      ) : (
        <UserDashboardEmptySection section="no-badges" />
      )}
    </div>
  );
};

export default UserDashboardView;
